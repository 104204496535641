import generateV4GUID from "./../services/guid"

const initState = {
  // activeState: "initial"
  // affiliates: [],
  adContent: {image: 0},
  archivedStateKeys: [],
  archivedStates: {},
  focusedInput: undefined,
  formContent: {images: []},
  formSubmitted: false,
  layoutIsOpen: true,
  modalIsOpen: false,
  route: 0,
  sessionId: generateV4GUID(),
  showLoadingScreen: true,
  stateFlags: {},
  stateHasBeenInitialized: false,
  staticContent: {images: []},
  step: 0,
  thankyouPageContent: [{images: []}],
  thankyouPageIsOpen: false
}

export const dispatchTypes = {
  set: "set/add multiple state values",
  initializeState: "set the initial state on page load if state has not already been set"
}

const reducers = {
  [dispatchTypes.set]: (state, payload) => {
    return {...state, ...payload}
  },
  [dispatchTypes.initializeState]: (state, payload) => {
    const {
      backButtonOnModal,
      config,
      devMode,
      disableScroll,
      enableScroll,
      failStates,
      formData,
      industry,
      inputKeys,
      modal,
      modalIsOpen,
      queryParamKeys,
      scrollTo,
      showThankyouPage,
      stateKey,
      styledInputs,
      submissionIsDisabled,
      tcpaInputKeys
    } = payload

    const stateFlags = {...state.stateFlags, [stateKey]: true}

    stateFlags[stateKey] = true

    const nextState = {
      ...state,
      stateHasBeenInitialized: true,
      ...formData,
      ...failStates,
      inputKeys,
      queryParamKeys,
      tcpaInputKeys,
      enableScroll,
      disableScroll,
      scrollTo,
      modal,
      modalIsOpen,
      backButtonOnModal,
      industry,
      submissionIsDisabled,
      thankyouPageIsOpen: showThankyouPage,
      devMode,
      ...config,
      styledInputs,
      activeState: stateKey,
      stateFlags
      }

    if (state.activeState) {
      const stateArchive = {...state.archivedStates}
      const currentState = {...state}
      currentState.stateHasBeenInitialized = true;  /* DAVID *///  LINK FLIPS THIS TO FALSE WHEN IT NEEDS TO DISPLAY LOADING SCREEN; NEEDS TO BE FLIPPED BACK ON-SAVE
      delete currentState.archivedStates
      delete currentState.sessionId
      delete currentState.trackingId
      stateArchive[state.activeState] = currentState  /* DAVID *///  STASH CURRENT STATE INTO `${archivedStates}`
      const archivedStateKeys = Object.keys(stateArchive)
      const archivedState = stateArchive[stateKey] || nextState
      const newState = {
        ...archivedState,
        sessionId: state.sessionId,
        trackingId: state.trackingId,
        archivedStates: stateArchive,
        activeState: stateKey,
        archivedStateKeys
      }
      return newState  /* DAVID *///  RETRIEVE ARCHIVED STATE AND SET IT TO CURRENT STATE
    } else {
      return nextState
    }
  }
}


///// REDUCER
function reducer(state = initState, action) {
  if (reducers[action.type]) {
    let res = reducers[action.type](state, action.payload)
    return res
  } else {
    return state
  }
}
export default reducer


///// DISPATCHER
export function reduxDispatcher(payload, type) {
  console.table(Object.entries(payload))
  return({type, payload})
}